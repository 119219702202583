import { db } from "@flowby/firebase";
import i18next, { changeLanguage } from "i18next";
import { useSessionStore } from "../libs/store";

export const LanguageProvider = ({
	children,
}: { children: React.ReactElement }) => {
	const store = location.pathname.split("/")[1];
	const currentLang = i18next.language;
	const userLang = useSessionStore((state) => state.userLanguage);
	if (userLang) {
		changeLanguage(userLang);
	} else if (store) {
		db.getStore(store).then((doc) => {
			const lang = doc.data()?.lang;
			if (lang && lang !== currentLang) {
				changeLanguage(lang);
			}
		});
	}

	return children;
};
