import "core-js";
import "proxy-polyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./reset.css";

console.log(`Flowby version: ${process.env.REACT_APP_VERSION}`);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
