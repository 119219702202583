import { db } from "@flowby/firebase";
import { useParams } from "react-router-dom";
import GroupKioskView from "../components/Kiosk/GroupKioskView";
import FlowbyError from "../components/shared/Error";
import { useCollData, useDocData } from "../libs/firebaseHooks";
import { useLoading } from "../providers/LoadingProvider";

export default function KioskPage() {
	const params = useParams();
	const store = params.store as string;
	const group = params.group as string;
	const [storeData, storeLoading] = useDocData(db.getStoreRef(store), [store]);
	const [groupData, groupLoading] = useDocData(db.getGroupRef(store, group), [
		store,
		group,
	]);
	const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [
		store,
	]);

	if (useLoading(groupLoading || queuesLoading || storeLoading)) return null;

	if (!groupData || !queuesData || !storeData) {
		return <FlowbyError />;
	}

	return (
		<GroupKioskView
			store={store}
			group={group}
			groupData={groupData}
			queuesData={queuesData}
			storeData={storeData}
		/>
	);
}
