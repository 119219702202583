import { db } from "@flowby/firebase";
import { useParams } from "react-router-dom";
import QueueKioskView from "../components/Kiosk/QueueKioskView";
import FlowbyError from "../components/shared/Error";
import { useCollData, useDocData } from "../libs/firebaseHooks";
import { useLoading } from "../providers/LoadingProvider";

export default function KioskPage() {
	const params = useParams();
	const store = params.store as string;
	const queue = params.queue as string;

	const [queueData, queueLoading] = useDocData(db.getQueueRef(store, queue), [
		store,
		queue,
	]);
	const [storeData, storeLoading] = useDocData(db.getStoreRef(store), [store]);
	const [groupsData, groupsLoading] = useCollData(db.getGroupsRef(store), [
		store,
	]);

	if (useLoading(storeLoading || queueLoading || groupsLoading)) return null;

	if (!queueData || !storeData || !groupsData) {
		return <FlowbyError />;
	}

	return (
		<QueueKioskView
			store={store}
			queue={queue}
			queueData={queueData}
			groupsData={groupsData}
			storeData={storeData}
		/>
	);
}
