import { db } from "@flowby/firebase";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import FlowbyError from "../components/shared/Error";
import { useLoading } from "../providers/LoadingProvider";
import { useDocData } from "../libs/firebaseHooks";

export const StoreGuard = () => {
	const { t } = useTranslation();
	const storeInactive = t("store-inactive-customer");
	const doesNotExist = t("does-not-exist");
	const params = useParams();
	const store = params.store as string;
	const [storeData, storeLoading, storeError] = useDocData(
		db.getStoreRef(store),
		[store],
	);

	if (useLoading(storeLoading)) return null;

	if (storeError) {
		return <FlowbyError />;
	}

	if (!storeData) {
		return <FlowbyError text={doesNotExist} />;
	}

	if (storeData.status === "inactive") {
		return <FlowbyError text={storeInactive} />;
	}

	return <Outlet />;
};
