import {
	ChakraProvider,
	extendTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/700.css";
import "./styles.css";
import { theme } from "@flowby/general";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./i18n";
import NotFound from "./pages/NotFoundPage";
import GroupKioskPage from "./pages/GroupKioskPage";
import GroupPage from "./pages/GroupPage";
import ConvertManualPage from "./pages/ConvertManualPage";
import QueueKioskPage from "./pages/QueueKioskPage";
import IndexPage from "./pages/IndexPage";
import StatusPage from "./pages/StatusPage";
import GetNewQueueNumberPage from "./pages/GetNewQueueNumberPage";
import QueuePage from "./pages/QueuePage";
import ThanksPage from "./pages/ThanksPage";
import { StoreGuard } from "./guards/StoreGuard";
import { LoadingProvider } from "./providers/LoadingProvider";
import { OfflineToastProvider } from "./providers/OfflineToastProvider";
import { LanguageProvider } from "./providers/LanguageProvider";

const chakraTheme = extendTheme(
	theme.styles,
	theme.components,
	theme.colors,
	withDefaultColorScheme({
		colorScheme: "teal",
	}),
);

const AppProviders = ({ children }: { children: React.ReactNode }) => {
	return (
		<ChakraProvider theme={chakraTheme}>
			<LoadingProvider>
				<LanguageProvider>
					<OfflineToastProvider>{children}</OfflineToastProvider>
				</LanguageProvider>
			</LoadingProvider>
		</ChakraProvider>
	);
};

export default function App() {
	return (
		<AppProviders>
			<BrowserRouter>
				<Routes>
					<Route path="/">
						<Route index element={<IndexPage />} />
						<Route path=":store" element={<StoreGuard />}>
							<Route path="status" element={<StatusPage />} />
							<Route path="q">
								<Route path=":queue">
									<Route index element={<GetNewQueueNumberPage />} />
									<Route path=":queuenumberid" element={<QueuePage />} />
									<Route path="thanks" element={<ThanksPage />} />
									<Route path="kiosk" element={<QueueKioskPage />} />
									<Route path="c">
										<Route path=":queuerId">
											<Route index element={<ConvertManualPage />} />
										</Route>
									</Route>
								</Route>
							</Route>
							<Route path="g">
								<Route path=":group">
									<Route index element={<GroupPage />} />
									<Route path="kiosk" element={<GroupKioskPage />} />
								</Route>
							</Route>
						</Route>
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</AppProviders>
	);
}
