import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../components/shared/Loader";

export const LoadingContext = createContext<{
	addLoading: () => void;
	removeLoading: () => void;
}>({
	addLoading: () => {},
	removeLoading: () => {},
});

export const LoadingProvider = ({
	children,
}: { children: React.ReactNode }) => {
	const [loadingCount, setLoadingCount] = useState(0);

	const addLoading = () => setLoadingCount((prev) => prev + 1);
	const removeLoading = () => setLoadingCount((prev) => Math.max(0, prev - 1));

	return (
		<LoadingContext.Provider value={{ addLoading, removeLoading }}>
			{loadingCount > 0 && <Loader />}
			{children}
		</LoadingContext.Provider>
	);
};

export const useLoading = (isLoading: boolean): boolean => {
	const { addLoading, removeLoading } = useContext(LoadingContext);

	useEffect(() => {
		if (isLoading) {
			addLoading();
		}
		return () => removeLoading();
	}, [isLoading, addLoading, removeLoading]);

	return isLoading;
};
